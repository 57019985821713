import React from "react";

const Footer = () => {
  return (
    <div className="py-5 bg-slate-950 text-white text-center">
      <p className="mb-0">
        &copy; {new Date().getFullYear()} techignitepro; All right reserved;
        Images used from{" "}
        <a href="freepik.com" target="_BLANK">
          freepik.com
        </a>
      </p>
    </div>
  );
};

export default Footer;
