import React from "react";
import "./App.css";
import Hero from "./components/Hero";
import About from "./components/About";
import Featured from "./components/Featured";
import PriceSection from "./components/PriceSection";
import Stats from "./components/Stats";
import Testimonials from "./components/Testimonials";
import ContactDetails from "./components/ContactDetails";
import Footer from "./components/Footer";

const App = () => {
  return (
    <div>
      <Hero />
      <About />
      <Featured />
      <PriceSection />
      <Stats />
      <Testimonials />
      <ContactDetails />
      <Footer />
    </div>
  );
};

export default App;
